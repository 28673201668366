import React, { FC } from 'react'
import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image'

import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  PageTitle,
  TitleBorder
} from '@bscs-dev-team/bscs-design-system-core'

import { GatsbyFluidImage } from '../../templates/template-types'

import Layout from '../../components/layout/layout'
import SEO from '../../components/seo'

import BSCSTransformingScienceEducationModel from '../../images/bscs-transforming-science-education-model.svg'

// eslint-disable-next-line
import rowImage from '../../queries/images/row-image'

type Data = {
  image1: GatsbyFluidImage,
  image2: GatsbyFluidImage,
  image3: GatsbyFluidImage,
  image4: GatsbyFluidImage
}

type WhatWeDoPageProps = {
  data: Data
}

const WhatWeDoPage: FC<WhatWeDoPageProps> = ({ data }: WhatWeDoPageProps) => {
  return (
    <Layout>
      <SEO
        title="Science Teacher Instructional Materials & Research & Courses"
        description="BSCS Science Learning is an independent nonprofit offering science teachers instructional materials, research & development  & training across the US."
        canonicalUrl="https://bscs.org/our-work/what-we-do"
      />
      <div>
        <Breadcrumb>
          <BreadcrumbItem>
            <Link className='inline-anchor' to='/'>Home</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            Our Work
          </BreadcrumbItem>
          <BreadcrumbItem>
            What We Do
          </BreadcrumbItem>
        </Breadcrumb>
        <PageTitle>What We Do</PageTitle>
        <TitleBorder />
        <p>
        At BSCS Science Learning, we are on a mission to transform science education nationwide. This means creating impact that is both systemic and sustainable. And we accomplish this goal through <strong>four areas</strong> of work.
        </p>
        <div className="flex flex-wrap-reverse mt-3">
          <div
            id="instructional-materials-development"
            className="p-4 w-full lg:w-1/2 bg-bscs-indigo-900 lg:rounded-tl"
          >
            <h3 className="text-bscs-gray-100">Instructional Materials Development</h3>

            <p className="text-bscs-gray-100">For teachers to be successful in the classroom, they must have access to high quality instructional materials. We leverage research insights and current industry standards to produce reliable curricula, as we’ve done since our earliest days.</p>

            <p className="text-bscs-gray-100">Our process begins with experimentation across small-scale settings. And based on our learnings from initial market tests and evaluations, we develop materials for broad dissemination.</p>

            <p className="text-bscs-gray-100">In today’s world, we primarily focus on developing NGSS-based, online, and highly interactive materials that meet the needs of increasingly diverse student populations.</p>
          </div>
          <div className="what-we-do-image-wrapper w-full lg:w-1/2">
            <Img
              className="h-full rounded-tr rounded-tl lg:rounded-tl-none"
              fluid={data.image1.childImageSharp.fluid}
              alt="male student holding an iPad that says 'Focus Question: What are the building blocks of living things?'"
            />
          </div>
        </div>
        <div className="flex flex-wrap">
          <div className="what-we-do-image-wrapper w-full lg:w-1/2">
            <Img
              className="h-full"
              fluid={data.image2.childImageSharp.fluid}
              alt="teacher with long hair and denim jacket pointing at something on a desk with three female fifth-grade students"
            />
          </div>
          <div
            id="teacher-professional-learning"
            className="p-4 what-we-do-text-section w-full lg:w-1/2 bg-bscs-gray-300"
          >
            <h3>Teacher Professional Learning</h3>

            <p className="text-bscs-gray-800">A thriving science education system depends on high quality professional learning opportunities for teachers. We are recognized for our signature approach to professional development, resulting from our 15-year line of research on how teachers and students learn science.</p>

            <p className="text-bscs-gray-800">Our approach to professional learning is proving to be powerful in both teacher preparation and continuing education programs; in district-wide programs and in programs enrolling individual teachers; in programs for elementary, middle, and high school teachers; and in programs facilitated in person and online.</p>
          </div>
        </div>
        <div className="flex flex-wrap-reverse">
          <div
            id="leadership-development"
            className="p-4 w-full lg:w-1/2 bg-bscs-indigo-900"
          >
            <h3 className="text-bscs-gray-100">Leadership Development</h3>

            <p className="text-bscs-gray-100">Support from the state level to the classroom level is needed to transform science education. That’s why we are committed to system-wide leadership development. We create and deliver research-driven programs that prepare schools, districts, and states to implement stronger systems and policies for science teaching and learning. </p>

            <p className="text-bscs-gray-100">Our programs provide education leaders with high quality professional learning opportunities and support in selecting and implementing high quality instructional materials. As industry research indicates, both components are essential for effective science instruction.</p>
          </div>
          <div className="what-we-do-image-wrapper w-full lg:w-1/2">
            <Img
              className="h-full"
              fluid={data.image3.childImageSharp.fluid}
              alt="young girl with long brown hair, white sweatshirt, and safety goggles on her forehead looking at a vial in her left hand as she puts rocks inside of it"
            />
          </div>
        </div>
        <div className="flex flex-wrap">
          <div className="what-we-do-image-wrapper w-full lg:w-1/2">
            <Img
              className="h-full lg:rounded-bl"
              fluid={data.image4.childImageSharp.fluid}
              alt="young boy with glasses and blue hoodie scrunching his face while looking at a vial in his right hand"
            />
          </div>
          <div
            id="research"
            className="p-4 p-4 what-we-do-text-section w-full lg:w-1/2 bg-bscs-gray-300 rounded-bl rounded-br lg:rounded-bl-none"
          >
            <h3>Research</h3>

            <p className="text-bscs-gray-800">Our work begins and ends with research. We conduct a variety of studies for a deeper understanding of how teachers and students learn science. Our evaluations span from studies of our own interventions across schools and districts to big-picture analyses across the greater science education landscape.</p>

            <p className="text-bscs-gray-800">This ongoing research allows us to explore the effectiveness of our instructional materials, professional learning, and leadership programs while informing our future innovation. Ultimately, we deliver enhanced products and services in more robust, research-informed settings.</p>
          </div>
        </div>
        <div className="mt-3">
          <div className="flex justify-center w-full">
            <h3>Our Model for Transforming Science Education</h3>
          </div>
          <div className="flex justify-center w-full">
            <div className="w-full lg:w-3/4">
              <img src={BSCSTransformingScienceEducationModel} alt="BSCS’s four areas of work: Leadership Development, Teacher Professional Learning, Instructional Materials, and Research. Leadership Development is important for the state, district, and school levels, while Teacher Professional Learning and Instructional Materials are important for the classroom level. Research and innovation are involved in each area of work." />
            </div>
          </div>
        </div>
        <div>
          <div className="flex justify-center">
            <h3>Our Work in Action</h3>
          </div>
          <div className="flex flex-wrap justify-center mt-3">
            <div className="w-full md:w-1/2 md:pr-2">
              <Link
                to="/our-work/rd-programs"
                title="Research & Development In Science Education - BSCS News"
                className="w-full"
              >
                <Button
                  className="w-full"
                  title="Research & Development In Science Education - BSCS News"
                  variant="indigo"
                >
                  <h4 className="text-lg font-bold tracking-tighter text-bscs-gray-100">R&amp;D Programs</h4>
                  <p className="text-bscs-blue-200">Sample of Current Projects</p>
                </Button>
              </Link>
            </div>
            <div className="w-full md:w-1/2 mt-2 md:mt-0 md:pl-2">
              <Link
                to="/resources/educator-resource-center"
                title="Science Teachers Instructional & Professional Learning Resources"
                className="w-full"
              >
                <Button
                  className="w-full"
                  title="Science Teachers Instructional & Professional Learning Resources"
                  variant="blue"
                >
                  <h4 className="text-lg font-bold tracking-tighter text-bscs-gray-100">Educator Resource Center</h4>
                  <p className="text-bscs-indigo-1000">Available Programs &amp; Products</p>
                </Button>
              </Link>
            </div>
          </div>
        </div>
        <div className="mt-3">
          <p><strong>Interested in partnering with us or hiring us to support your science education advancement efforts? Let’s explore how we can <Link to="/connect/work-with-us" title="Science Education Instructional Materials & Research">work together</Link>.</strong></p>
        </div>
      </div>
    </Layout>

  )
}

export default WhatWeDoPage

export const query = graphql`
  query {
    image1: file(relativePath: { eq: "index/ipad-screen.jpg" }) {
      ...rowImage
    }
    image2: file(relativePath: { eq: "index/teacher-and-students.jpg" }) {
      ...rowImage
    }
    image3: file(relativePath: { eq: "index/young-girl.jpg" }) {
      ...rowImage
    }
    image4: file(relativePath: { eq: "index/young-boy.jpg" }) {
      ...rowImage
    }
  }
`
